.body {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.header {
  width: 100%;
}
h1 {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: #5d616f;
  align-items: center;
  justify-content: center;
  display: flex;
}
.text {
  width: 60%;
  margin-bottom: 20px;
  color: #000;
  font-weight: 500;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.text-after {
  width: 100%;
  color: #000;
  font-weight: 400;
  font-size: 18px;
}
.text-title {
  margin: 0px;
  padding: 0px;
  max-width: 680px;
}
.signin-form {
  font-size: 13px;
  margin-bottom: 20px;
  width: 320px;
}
.signin-form__row {
  margin-bottom: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: #bdbdbd;
  border-radius: 8px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
  font-size: 16px;
  height: 48px;
  width: 214px;
  box-sizing: border-box;
  padding: 12px 0px 12px 20px;
}
.signin-form__row::placeholder {
  color: #bdbdbd;
}
.signin-form__row_submit {
  height: 48px;
  width: 214px;
  box-sizing: border-box;

  color: #ffffff;
  background-color: #0043bf;
  border-width: 1px;
  border-style: solid;
  border-color: #0043bf;

  display: inline-block;
  vertical-align: middle;
  text-align: center;
  border-radius: 8px;
  cursor: pointer;

  font-weight: 400;
  font-size: 16px;
}
.signin-form__row_submit:hover {
  background-color: #3f8be8;
  border: none;
}
.signin-form__row_submit-disabled {
  background-color: #e7eaf3;
  height: 44px;
  width: 100%;
  box-sizing: border-box;

  color: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-color: #e7eaf3;

  display: inline-block;
  vertical-align: middle;
  text-align: center;
  border-radius: 4px;

  font-size: 16px;
  letter-spacing: 0.5px;
  font-family: 'Open Sans', sans-serif;
}
.signin-form__footer {
  font-size: 14px;
  line-height: 19px;
  color: #95a3b4;
  text-align: center;
}
.error-message {
  color: #ff596d;
  width: 320px;
}
.logo-img {
  margin-top: 80px;
  margin-bottom: 180px;
}
@media (max-width: 600px) {
  .logo-img {
    margin-top: 80px;
    margin-bottom: 80px;
  }
  .text {
    width: 90%;
  }
  .signin-form {
    width: 100%;
  }
  .signin-form__row {
    width: 90%;
  }
  .signin-form__row_submit {
    width: 90%;
  }
}
